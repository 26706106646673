import {Alert, Button, ButtonGroup, Form} from "reactstrap";
import {UncontrolledSettingGroup} from "./settingsGroup";
import {FormField} from "./input";
import React, {useEffect, useState} from "react";
import {getHeader, setUserSession} from "../service/sessionStorage";
import axios from "axios";
import {handleError} from "../service/apiErrorHandler";
import MultiSelect from "./multiSelect";
import RunReportDetails from "./runReportDetails"
import {getUrl, reportTypes} from "../docs/data";
import {pollRunResults} from "../service/pollRunResults";
import {handleReload, reportRequestValidation} from "../service/utils";


const ReportManagementGroup = ({state, setState, qboAccount, callLock, setCallLock}) => {
    const header = getHeader()
    const [buttonType, setButtonType] = useState('historical')
    const [progress, setProgress] = useState(0)
    const [showProgress, setShowProgress] = useState(false)

    const [message, setMessage] = useState('')
    const [visible, setVisible] = useState(false)
    const [alertColor, setColor] = useState('')
    const [reloadBtn, setReloadBtn] = useState(false)

    useEffect(() => {
        if (state) setState({...state, ['start_date']: '', ['end_date']: '', ['run_report_types']: []})
        setVisible(false)
    }, [qboAccount])


    const makeRunRequest = async (event) => {
        event.preventDefault()
        if (!callLock.status) return
        const url = buttonType === 'historical' ? getUrl('historical') : getUrl('remove_reports')
        const resultName = buttonType === 'historical' ? 'historical_run_result' : 'remove_run_result'
        const messageHandler = {
            'setMessage': setMessage,
            'setVisible': setVisible,
            'setColor': setColor,
            'setReloadBtn':setReloadBtn}

        const invalidateMsg = reportRequestValidation(state)
        if (invalidateMsg) {
            setMessage(invalidateMsg)
            setVisible(true)
            setColor('warning')
            setReloadBtn(false)
            return
        }

        setProgress(0)
        const requestBody = {
            'qbo_acc_name': qboAccount,
            'start_date': state.start_date,
            'end_date': state.end_date,
            'report_types': state.run_report_types
        }

        console.log('Making a run request', state.start_date, state.run_report_types, url)
        console.log('body', requestBody)
        try {
            setCallLock({qboAccount: qboAccount, status: false})
            const response = await axios.post(url, requestBody, header);
            if (response.status === 200) {
                await setUserSession(response.data, response.headers);
                setMessage('Sync successfully started, please do not reload page or perform any actions until it is finished')
                setVisible(true)
                setColor('primary')
                setReloadBtn(false)
                setShowProgress(true)
                await pollRunResults(qboAccount, resultName, setProgress, messageHandler)
                setCallLock({qboAccount: qboAccount, status: true})
                }
            } catch (e) {
                handleError(e, setMessage, setVisible, setColor);
                setCallLock({qboAccount: qboAccount, status: true})
            }
    }

    return (
        <UncontrolledSettingGroup groupName='Manual Report Sync Settings'>
            <Form className="form" onSubmit={makeRunRequest}>
                <FormField groupName='Reports` Start Date'
                           formName='Reports` Start Date'
                           placeholder=''
                           name='start_date'
                           type='date'
                           state={state}
                           setState={setState}
                           tooltipContent='This will be the first report date'/>
                <FormField groupName='Reports` End Date'
                           formName='Reports` End Date'
                           placeholder=''
                           name='end_date'
                           type='date'
                           state={state}
                           setState={setState}
                           tooltipContent='Until & not including this date'/>
                <MultiSelect groupName='Module Types'
                             formName='Module Types'
                             name='run_report_types'
                             state={state}
                             setState={setState}
                             qboAccount={qboAccount}
                             tooltipContent='Modules that will be used during reports upload'
                />
                <div>
                    {
                        state['run_lock'] ?
                        <RunReportDetails state={state} qboAccount={qboAccount}></RunReportDetails>
                        : null
                    }
                    {
                        showProgress === true ?
                        <label id='syncProgressBar'>
                            Sync Progress: <progress value={progress} max="100">{progress} %</progress>
                        </label>
                        : null
                    }
                </div>

                <br></br>
                {message &&
                    <Alert color={alertColor} isOpen={visible} toggle={() => setVisible(false)} className="message">
                    {message}
                        {
                            reloadBtn === true ?
                                <span
                                    onClick={handleReload}
                                    style={{
                                        color: '#007bff',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        marginLeft: '10px'
                                    }}
                                >
                                Refresh and see Details
                                </span>
                            : null
                        }
                    </Alert>
                }
                <br></br>

                <ButtonGroup>
                    <Button type="submit" name="remove_reports"  onClick={() => setButtonType('remove_reports')} color="secondary">
                        Remove Reports
                    </Button>
                    <Button type="submit" name="historical" onClick={() => setButtonType('historical')} color="success">
                        Add Reports
                    </Button>
                </ButtonGroup>
            </Form>
        </UncontrolledSettingGroup>
    )
}

export {ReportManagementGroup};