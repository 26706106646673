import React from 'react';
import {Route} from 'react-router-dom';
import {getSessionItem} from '../service/sessionStorage';

const PrivateRoute = ({setLogin, component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (sessionStorage.getItem('token')) {
                    setLogin(true)
                    return <component.advanced{...props} />
                } else {
                    setLogin(false)
                    props.history.push('/login-pg')
                }
            }
            }
        />
    )
}

export default PrivateRoute