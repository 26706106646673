import React, {useState, useEffect} from "react";
import {Table} from "reactstrap";

export default function JsonToTable({tbodyData}) {
    const [head, setHead] = useState([])
    useEffect(() => {
        if (tbodyData && tbodyData.length > 0) {
            setHead(Object.keys(tbodyData[0])); // Set the table headers from the first row of data
        }
    }, [tbodyData]); // Re-run when tbodyData changes

    // If there's no data yet, show a loading or empty state
    if (!tbodyData || tbodyData.length === 0 || head.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <Table>
            <thead>
            <tr>
                {head.map((heading, index) => (
                    <th key={index}>{heading}</th>
                ))}
            </tr>
            </thead>
            <tbody>
                {tbodyData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {head.map((key, colIndex) => (
                            <td key={colIndex}>{row[key]}</td> // Use column index for a stable key
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}