const timeZones = ['US/Pacific','US/Mountain','US/Central','US/Eastern','US/Alaska','US/Hawaii']

const integrationNames = ['DUTCHIE', 'FLOWHUB', 'COVA', 'CANIX']

const defaultDebitTypes = {'debitPaid': 'Debit Clearing Account',
                            'checkPaid': 'Check Clearing Account',
                            'creditPaid': 'Credit Clearing Account',
                            'giftPaid': 'GiftCard Clearing Account',
                            'prePaymentAmount': 'Dutchie Pay Clearing Account',
                            'debit': 'Debit Clearing Account',
                            'giftcard': 'Debit Clearing Account',
                            'IntegratedCardPayment': 'Debit Clearing Account'}

const inventoryCategoryStatus = 'inventoryCategoryStatus'
const userSettings = {'': {integration_name: undefined}}

const accountingBasis = ['Accrual', 'Cash']

const reportTypes = [
    { value: 'TRANSACTION', label: 'Vault Transfers'},
    { value: 'INVENTORY', label: 'Bills'},
    { value: 'INCOME', label: 'Sales & Refunds'},
    { value: 'DEBIT', label: 'Debits'},
    { value: 'OVERSHORT', label: 'OverShorts'},
    { value: 'OVERSHORT_LITE', label: 'OverShorts'},
    { value: 'MIMO_CASHDROP', label: 'Mimo Cashdrop'},
    { value: 'MIMO_VENDOR_PAYOUT', label: 'Mimo Vendor Payouts'}
]

const getUrl = (endpointName) => {
    return 'https://' + window.location.hostname + '/' + endpointName
}
export {userSettings, integrationNames, timeZones, getUrl, inventoryCategoryStatus, accountingBasis, defaultDebitTypes, reportTypes}