import axios from 'axios';
import {getHeader, setUserSession} from './sessionStorage'
import {handleError} from "./apiErrorHandler";
import Cookies from "js-cookie";
import {getUrl} from "../docs/data";


function makeAuthCall(setVisible, setMessage, setColor) {
    console.log('Making auth call');
    let header = getHeader()
    const url = getUrl('auth')
    axios.get(url, header)
        .then(response => {
            if (response.status === 200) {
                console.log('The response body qbo url:', response.data);
                window.location.href = response.data;
            }
        }).catch(error => handleError(error, setMessage, setVisible, setColor));
}

function makeDeauthCall(qboAccount, setVisible, setMessage, setColor) {
    console.log('Making deauth call for account ', qboAccount)
    let header = getHeader()
    let requestBody = {'qbo_acc_name': qboAccount}
    const url = getUrl('deauth')
    axios.post(url, requestBody, header)
        .then(function (response) {
            if (response.status === 200) {
                setUserSession(response.data, response.headers);
                setMessage('QBO account ' + qboAccount + ' disconnected successfully!');
                setVisible(true);
                setColor('primary');
            }
        }).catch(error => handleError(error, setMessage, setVisible, setColor));
}

function makeQboCheck(setMessageQBO, setColor, setVisible) {
    let QboConnectStat = Cookies.get('qbo-connection-result');
    Cookies.remove('qbo-connection-result')
    console.log('Searching qbo-connection-result', QboConnectStat)
    if (QboConnectStat === 'exists') {
        setMessageQBO('QBO account already connected, try another one or refresh the page.');
        setColor('warning')
        setVisible(true)
    } else if (QboConnectStat === 'success') {
        setMessageQBO('QBO account connected successfully.');
        setColor('primary')
        setVisible(true)
    } else {
        return false
    }
}

export {makeAuthCall, makeDeauthCall, makeQboCheck}
