export const settingsValidation = (qboAccount, state) => {
    let result
    switch(true) {
        case !qboAccount:
            result = 'QBO Account required.'
            break
        case (!state.integration_name):
            result = 'Integration name is required.'
            break
        case (['DUTCHIE', 'CANIX'].includes(state.integration_name) && !state.pos_timezone):
            result = 'Time Zone field is required.'
            break
        case (!state.parent_acc_name):
            result = 'Parent Bank Account Name is required.'
            break
        case (state.dutchie_key && state.dutchie_key.length !== 32):
            result = 'Check Dutchie key: should be 32 characters long'
            break
        case ((state.cova_company_id && state.cova_company_id.length !== 6) || (state.cova_location_id && state.cova_location_id.length !== 6)):
            result = 'Check Cova Location Id: should be 6 characters long'
            break
        case (state.canix_token && state.canix_token.length !== 64):
            result = 'Check Canix token: should be 64 characters long'
            break
        case ((state.flow_token && state.flow_token.length !== 36) || (state.flow_client_id && state.flow_client_id.length !== 36) || (state.flow_location_id && state.flow_location_id.length !== 36)):
            result = 'Check Flow token, ClientId, LocationId: should be 36 characters long'
            break
        default:
            result = false
    }
    return result
}

export const reportRequestValidation = (state) => {
    let result
    let today = Date.now()
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    switch(true) {
        case (!state.run_report_types || state.run_report_types.length === 0):
            result = 'At least one report type is required'
            break
        case (!state.start_date || !state.end_date):
            result = 'Date interval is required'
            break
        case (state.start_date > state.end_date):
            result = "Start Date can't be later than End Date"
            break
        case (Date.parse(state.start_date) > today):
            result = "Start Date can't be in the future"
            break
        case (Date.parse(state.end_date) > tomorrow):
            result = "End Date can't be more than one day ahead"
            break
        case (state.start_date === state.end_date):
            result = "Start and End Date can't be the same"
            break
        default:
            result = false
    }
    return result
}

export const checkWarnings = (state, setVisible, setMessage) => {
    if (state.warnings && state.warnings.length > 0) {
        let messageArr = []
        if (state.warnings.includes('tax_agent_exists')) {
            let agentMsg = "Activated Tax Agent detected in QBO file. This may cause services insode sales receipts to be created as taxable."
            messageArr.push(agentMsg)
        }
        if (state.warnings.includes('flowhub_permission_constraint')) {
            let fhMsg = "Additional Flowhub permissions required to support vendor billing. Refer to our manager for more details."
            messageArr.push(fhMsg)
        }
        console.log('WARNINGS', state.warnings)
        setMessage(messageArr)
        setVisible(true)
    }
}

export const pageAccessedByReload = (
    (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
)

export const convertDateFormat = (dateString) => {
    // Use Intl.DateTimeFormat to format the date in the user's local format
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat().format(date);
    return formattedDate
}

export const handleReload = () => {
    window.location.reload()
}

export const splitCamelCase = (str) => {
    // Add a space before each uppercase letter (except the first one)
    return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
}
