import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    Row
} from 'reactstrap';
import axios from 'axios';
import Cookies from 'js-cookie';

import {makeAuthCall, makeDeauthCall, makeQboCheck} from './service/qboAuthService';
import {AccountsSelector, BasicSelector} from './components/select';
import {getHeader, setUserSession, getAccNames, getUserSettings} from './service/sessionStorage';
import {handleError} from "./service/apiErrorHandler";
import BugSubmissionModal from "./components/modal";
import {TransactionSettings} from "./components/reports/TransactionSettings";
import {ReportManagementGroup} from "./components/reportManagmeent";
import {getUrl, integrationNames} from "./docs/data";
import {IncomeSettings} from "./components/reports/IncomeSettings";
import {DebitSettings} from "./components/reports/DebitSettings";
import {InventorySettings} from "./components/reports/InventorySettings";
import {OvershortSettings} from "./components/reports/OvershortSettings";
import {GlobalSettings} from "./components/reports/GlobalSettings";
import {checkWarnings, pageAccessedByReload, settingsValidation, splitCamelCase} from "./service/utils";


const Settings = (props) => {
    const [qboAccount, selectAccount] = useState(undefined)
    const [callLock, setCallLock] = useState({qboAccount: undefined, status: true})
    const [accountNames, setAccountNames] = useState(undefined)
    const [state, setState] = useState('')

    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [alertColor, setColor] = useState('')

    const [messageQBO, setMessageQBO] = useState('')
    const [visibleQBO, setVisibleQBO] = useState(false)

    const [messageWarning, setMessageWarning] = useState([]);
    const [visibleWarning, setVisibleWarning] = useState(false);

    useEffect(() => {
        makeQboCheck(setMessageQBO, setColor, setVisibleQBO)
            console.log('Page refreshed')
            const url = getUrl('verify')
            let header = getHeader()
            setCallLock({qboAccount: qboAccount, status: false})
            axios.get(url, header)
                .then(function (response) {
                    if (response.status === 200) {
                        setUserSession(response.data, response.headers)
                        getAccNames(setAccountNames, setMessage, setVisible, setColor)
                        setCallLock({qboAccount: qboAccount, status: true})
                    }
                })
                .catch((error) => {
                    handleError(error, setMessage, setVisible, setColor)
                    setCallLock({qboAccount: qboAccount, status: true})
                })
    }, [])

    useEffect(() => {
        setVisible(false)
        checkWarnings(state, setVisibleWarning, setMessageWarning)
    }, [qboAccount])

    const onQBOMessageDismiss = () => {
        setVisibleQBO(false);
        Cookies.set('qbo-con-status', null);
    }

    const deauthAccount = () => {
        if (qboAccount && callLock.status) {
            makeDeauthCall(qboAccount, setVisibleQBO, setMessageQBO, setColor)
        }
    }

    const saveSettings = (event) => {
        event.preventDefault()
        if (!callLock.status) return

        const invalidateSettingsMsg = settingsValidation(qboAccount, state)
        if (invalidateSettingsMsg) {
            setMessage(invalidateSettingsMsg)
            setVisible(true);
            setColor('warning')
            return
        }

        let namingMaps = ['inventory_vendor_naming_map', 'income_tax_naming_map', 'income_tracking_class_map', 'canix_facility_type_map']
        for (let item of namingMaps) {
            if (typeof(state[item]) === 'string') {
                try {
                    state[item] = JSON.parse(state[item])
                }
                catch (SyntaxError) {
                    setMessage('Check ' + item + ' formatting');
                    setVisible(true);
                    setColor('warning')
                    return
                }
            }
        }

        // putting acc name to the cash basis param if there is input from user
        if (state.accounting_basis === 'Cash' && state.inventory_acc_name) {
            state.inventory_cash_basis_acc_name = state.inventory_acc_name
        }

        if (state.integration_name === 'COVA' && state.debit_payment_types) {
            // convert all keys of the debit_payment_types object into split camel case
            state.debit_payment_types = Object.keys(state.debit_payment_types).reduce((acc, key) => {
                const newKey = splitCamelCase(key)
                acc[newKey] = state.debit_payment_types[key]
                return acc
            }, {})
        }

        if (!state.inventoryCategoryStatus) {
            state.inventory_category_map = {}
        }

        let requestBody = {};
        requestBody['settings'] = state
        requestBody['qbo_acc_name'] = qboAccount
        let header = getHeader()
        const url = getUrl('save_settings')

        setCallLock({qboAccount: qboAccount, status: false})
        console.log('Saving settings with body ', requestBody)
        axios.post(url, requestBody, header)
            .then(response => {
                if (response.status === 200) {
                    setUserSession(response.data, response.headers);

                    setMessage('Settings saved successfully');
                    setVisible(true);
                    setColor('primary')

                    checkWarnings(state, setVisibleWarning, setMessageWarning)
                    getAccNames(setAccountNames, setMessage, setVisible, setColor)
                    setCallLock({qboAccount: qboAccount, status: true})
                }
            })
            .catch(error => {
                handleError(error, setMessage, setVisible, setColor)
                setCallLock({name: qboAccount, status: true})
            })
    }

    const qboAccNames = () => {
        return accountNames ? accountNames[qboAccount] : accountNames
    }

    const enableDisableRequest = (event) => {
        event.preventDefault()
        if (!callLock.status) return
        const header = getHeader()
        const requestBody = {'qbo_acc_name': qboAccount}
        const url = getUrl('enable_disable')
        setCallLock({qboAccount: qboAccount, status: false})
        axios.post(url, requestBody, header)
            .then(response => {
                if (response.status === 200) {
                    setUserSession(response.data, response.headers);
                    setState({...state, ['status']: !state.status})
                    setMessageQBO(state.status ? 'Account disabled successfully' : 'Account enabled successfully');
                    setVisibleQBO(true);
                    setColor('primary')
                    setCallLock({qboAccount: qboAccount, status: true})
                }
            })
            .catch(error => {
                handleError(error, setMessage, setVisible, setColor)
                setCallLock({name: qboAccount, status: true})
            })
    }

    return (
        <Container className="border-light" fluid>
            <div className="Main">
                <br></br>
                <h5>Settings</h5>
                <br></br>

                <Row>
                    <Col xs="5">
                        <AccountsSelector setState={setState} selectAccount={selectAccount} setAccountNames={setAccountNames}/>
                    </Col>
                    <Col xs="5">
                        <ButtonGroup>
                            <Button color="success" value="Connect QBO" onClick={() => makeAuthCall(setVisibleQBO, setMessageQBO, setColor)}> Connect to QBO </Button>
                            <Button color="danger" value="Disconnect QBO" onClick={deauthAccount}> Disconnect from QBO </Button>
                            {/*<Button color="danger" value="SHOW QBO" onClick={() => console.log('STATE', state)}> Show State </Button>*/}
                        </ButtonGroup>
                    </Col>
                        <Col>
                            <Button id="enableDisableBtn" type="button" onClick={enableDisableRequest} color={state.status ? 'danger' : 'success'} >
                            {state.status ? 'Disable App' : 'Enable App'}
                            </Button>
                        </Col>
                    <BasicSelector formName='Integration Name'
                                   name='integration_name'
                                   componentName='IntegrationSelector'
                                   items={integrationNames}
                                   state={state}
                                   setState={setState}
                                   tooltipContent="Pick the POS software you're using"/>
                </Row>
                <Alert color={alertColor} isOpen={visibleQBO} toggle={onQBOMessageDismiss} className="message">{messageQBO}</Alert>
                <Form id="mainForm" className="form" onSubmit={saveSettings}>
                    <GlobalSettings state={state} setState={setState} qboAccNames={qboAccNames()}/>
                    <TransactionSettings state={state} setState={setState} qboAccNames={qboAccNames()}/>
                    <IncomeSettings state={state} setState={setState}/>
                    <DebitSettings state={state} setState={setState} qboAccNames={qboAccNames()}/>
                    <InventorySettings state={state} setState={setState} qboAccount={qboAccount} qboAccNames={qboAccNames()}/>
                    <OvershortSettings state={state} setState={setState} qboAccNames={qboAccNames()}/>

                    {<Alert color={alertColor} isOpen={visible} toggle={() => setVisible(false)} className="message">{message}</Alert>}
                    {messageWarning.map(msg => <Alert color='warning' isOpen={visibleWarning} toggle={() => setVisibleWarning(false)} className="message">{msg}</Alert>)}
                    <Button type="submit" value="Save" color="success" >
                        Save settings
                    </Button>
                    <BugSubmissionModal qboAccount={qboAccount}/>
                    <br></br>
                </Form>
                <ReportManagementGroup state={state} setState={setState} qboAccount={qboAccount} callLock={callLock} setCallLock={setCallLock}/>
            </div>
        </Container>
    )
}

export default Settings;