import React, {useEffect, useState} from 'react';
import {
    FormGroup,
    Col,
    Label,
    Input, UncontrolledTooltip
} from 'reactstrap';
import {getUserSettings} from "../service/sessionStorage";

function AccountsSelector({setState, selectAccount, setAccountNames}) {
    const userSettings = getUserSettings()

    useEffect(() => {
        const firstKey = Object.keys(userSettings).map(name => name)[0]
        selectAccount(firstKey)
        setState({...userSettings[firstKey]})
    }, [])

    const options = [...Object.keys(userSettings).map(name => <option key={name} value={name}>{name}</option>)]

    const handleInput = event => {
        document.getElementById("mainForm").reset()
        selectAccount(event.target.value)
        console.log('Account selected:', event.target.value)
        setState({...userSettings[event.target.value]})
        // setAccountNames(undefined)
    }

    return (
        <FormGroup row>
            <Label for="exampleSelect" sm="3">
                QBO Account
            </Label>
            <Col>
                <Input id="dropdown"
                       name="select"
                       type="select"
                       onChange={handleInput}>
                    {options}
                </Input>
            </Col>
        </FormGroup>
    );
}

function BasicSelector(props) {
    const [value, setValue] = useState('')
    useEffect(() => {setValue(props.state[props.name])}, [props.state])

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        props.state[name] = value
        props.setState({...props.state})
        setValue(value)
    };

    return (
        <FormGroup>
            <Col>
                <Label for="exampleSelect" sm={2} id={props.name}>
                    {props.formName}
                </Label>
                <Input
                    className={props.componentName}
                    name={props.name}
                    type="select"
                    value={value}
                    onChange={handleInput}
                >
                    {props.items.map(opt => <option value={opt} selected={props.state[props.name] === opt ? true : null}>{opt}</option>)}
                </Input>
                {props.tooltipContent &&
                    <UncontrolledTooltip placement="top" target={props.name}>
                        {props.tooltipContent}
                    </UncontrolledTooltip>}
            </Col>
        </FormGroup>
    )
}


export { AccountsSelector, BasicSelector };