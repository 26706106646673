import Select from 'react-select';
import React, {useEffect, useState} from "react";
import {FormGroup, Label, UncontrolledTooltip} from 'reactstrap';
import {reportTypes} from "../docs/data";


function MultiSelect({groupName, formName, name, state, setState, qboAccount, tooltipContent}) {
    const [options, setOption] = useState([])
    const [value, setValue] = useState('')

    useEffect(() => {
        setValue('')
    }, [qboAccount])

    useEffect(() => {
        const enabledReports = state['report_types'] ? state['report_types'] : []
        const currentOptions = reportTypes.filter((x) => enabledReports.includes(x.value))
        setOption(currentOptions)
    }, [state.report_types])

    const handleInput = (event) => {
        let choices = JSON.stringify(event.map(x => x.value))
        state[name] = choices
        setState({...state})

        setValue(event)
    }

    return (
        <FormGroup>
            <Label for={groupName} id={name} targetId={name}>
                {formName}
            </Label>
            <Select
                isMulti
                name="colors"
                value={value}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleInput}
            />
            <UncontrolledTooltip placement="top" target={name}>
                {tooltipContent}
            </UncontrolledTooltip>
        </FormGroup>
    );
}

export default MultiSelect;