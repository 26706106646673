import {FormField} from "../input";
import {SettingsGroup} from "../settingsGroup";
import React from "react";
import {Toggle} from "../toggle";
import {SingleSelect} from "../singleSelect";

export const OvershortSettings = ({state, setState, qboAccNames}) => {
    if (['FLOWHUB', 'CANIX', 'COVA'].includes(state.integration_name)) return

    return (
        <SettingsGroup groupName={<div id='OVERSHORT_LITE'>Over/Short</div>}
                       reportType='OVERSHORT_LITE'
                       name='report_types'
                       state={state}
                       setState={setState}
                       tooltipContent='Turn on to enable QBO Bills. Module creates journal entries for each overshort based on closeout amounts.
                                                Granularity: by closeout / single entry in SalesReceipt'>
            <Toggle toggleName='Include OverShort in Sales Receipt'
                    state={state}
                    setState={setState}
                    name='overshort_single_register'
                    tooltipContent='Includes overshort line as an item into a SalesReceipt. Works only if "Lite mode" is turned on in Sales&Refunds'/>
            <SingleSelect groupName='Over/Short Journal Entry'
                          formName='Cash variances account name'
                          name='cash_var_acc_name'
                          setState={setState}
                          state={state}
                          options={qboAccNames}/>
        </SettingsGroup>
    )
}